import { isNumeric, shallowEqual } from '@utils/helpers'
import {
    ConfigLogic,
    ConfigLogicAction,
    ConfigLogicActionCondition,
    ConfigLogicActionVariable,
    ConfigLogicStep,
    ConfigLogicSteps,
} from '@common/types/form/formConfig'
import { DraftField, DraftFieldType, DraftFieldValue, FormDraft, StepDraft } from '@common/types/form/formDraft'

/**
 *
 * @param field
 * @param stepDraft
 * @param fieldsLogic
 * @returns A boolean indicating if a field should be rendered or not, based on the fields logic.
 */
export const validateFieldRender = (
    field: DraftField,
    stepDraft: StepDraft | null,
    fieldsLogic: ConfigLogic['fields']
) => {
    const fieldLogic = fieldsLogic?.[field.uuid]

    if (!fieldLogic) return true

    switch (fieldLogic.type) {
        case 'visibility': {
            return computeCondition(stepDraft, fieldLogic.condition)
        }

        // As a default we don't show the field at all. This case should only be reached if the logic type sent from the backend is not supported.
        default:
            return false
    }
}

/**
 *
 * @param formDraft
 * @param stepUuid
 * @param stepsLogic
 * @param initialSortedStepUuids
 * @returns The uuid of the next step to navigate to when a logic exists.
 */
export const computeNextStepUuid = (
    formDraft: FormDraft | null,
    stepUuid: string,
    stepsLogic: ConfigLogicSteps | undefined,
    initialSortedStepUuids: string[] | undefined
): string | null => {
    const stepLogic = stepsLogic?.[stepUuid]
    const stepDraft = formDraft?.[stepUuid] || null

    // If there is no logic for a step, the next step is the next step in the sorted steps array.
    if (!stepLogic) {
        const currentStepIndex = initialSortedStepUuids?.indexOf(stepUuid)

        if (!isNumeric(currentStepIndex)) return null

        return initialSortedStepUuids?.[currentStepIndex + 1] || null
    }

    switch (stepLogic.type) {
        case 'jump': {
            return computeDestinationJumpLogic(stepDraft, stepLogic)
        }

        default:
            return null
    }
}

/**
 *
 * @param stepDraft
 * @param stepLogic
 * @returns The destination step uuid for a jump logic, when the defined condition is met.
 */
export const computeDestinationJumpLogic = (
    stepDraft: StepDraft | null,
    stepLogic: ConfigLogicStep | null
): string | null => {
    if (!stepDraft || !stepLogic) return null
    const verifiedAction = stepLogic.actions.find<ConfigLogicAction>((action): action is ConfigLogicAction =>
        computeCondition(stepDraft, action.condition)
    )

    if (!verifiedAction) return null

    return getDestination(verifiedAction.destination)
}

/**
 *
 * @param stepDraft
 * @param condition
 * @returns A boolean indicating if the condition is met or not.
 */
export const computeCondition = (stepDraft: StepDraft | null, condition: ConfigLogicActionCondition): boolean => {
    const { operator, variables } = condition

    switch (operator) {
        /**
         * In the case of an 'and' or 'or' operator, variables are conditions, this allows the chaining of multiple nested conditions.
         */
        case 'and': {
            return (
                (variables as ConfigLogicActionCondition[])
                    .map((variable) => computeCondition(stepDraft, variable))
                    .filter((value) => !value).length === 0
            )
        }
        case 'or': {
            return (
                (variables as ConfigLogicActionCondition[])
                    .map((variable) => computeCondition(stepDraft, variable))
                    .filter((value) => value).length === 1
            )
        }

        case 'else': {
            return true
        }
    }

    const parsedReferenceVariable = parseVariable(variables[0], stepDraft)
    const parsedComperativeVariable = parseVariable(variables[1], stepDraft)

    if (!parsedReferenceVariable || !parsedComperativeVariable) return false

    switch (operator) {
        case 'equals': {
            if (Array.isArray(parsedReferenceVariable) && Array.isArray(parsedComperativeVariable)) {
                return shallowEqual(parsedReferenceVariable, parsedComperativeVariable)
            }

            // dirty hack to overcome deeper problem with
            // dataformat for multiselect not working with logic
            // because multiselect value not parsed correctly.
            // it will look like '{"moisture"}' === 'moisture'
            // which is parsed for frontend to be ['moisture'] === 'moisture'
            // todo: remove when deeper problem is fixed
            if (Array.isArray(parsedReferenceVariable) && !Array.isArray(parsedComperativeVariable)) {
                return parsedReferenceVariable[0] === parsedComperativeVariable
            }

            return parsedReferenceVariable === parsedComperativeVariable
        }
        case 'notEquals': {
            return parsedReferenceVariable !== parsedComperativeVariable
        }
        case 'greaterThan': {
            return parsedReferenceVariable > parsedComperativeVariable
        }
        case 'greaterThanOrEquals': {
            return parsedReferenceVariable >= parsedComperativeVariable
        }
        case 'lessThan': {
            return parsedReferenceVariable < parsedComperativeVariable
        }
        case 'lessThanOrEquals': {
            return parsedReferenceVariable <= parsedComperativeVariable
        }

        // As a default we don't show the field at all. This case should only be reached if the operator sent from the backend is not supported.
        default: {
            return false
        }
    }
}

/**
 *
 * @param variable
 * @param stepDraft
 * @returns The value to compare against in a condition, dependent on the type of the variable.
 */
const parseVariable = (variable: ConfigLogicActionVariable, stepDraft: StepDraft | null) => {
    if (variable.type === 'field') {
        if (!stepDraft) return null

        const fieldValue = stepDraft[variable.value]

        if (variable.nestedKey) {
            return getNestedKeyValue(fieldValue, variable.nestedKey)
        }

        return stepDraft[variable.value] || null
    } else {
        return variable.value
    }
}

/**
 *
 * @param destination
 * @returns The destination step uuid dependent on the type of the destination.
 */
export const getDestination = (destination: ConfigLogicAction['destination']): string | null => {
    switch (destination.type) {
        case 'step': {
            /**
             * We have to trust the backend to send a valid field uuid as value.
             */
            return destination.value
        }

        /**
         * In this case, the step should be the final one.
         */
        case 'end': {
            return null
        }

        /**
         * The default case could occure, if the backend sends an invalid destination type.
         */
        default:
            return null
    }
}

/**
 *
 * @param fieldValue
 * @param nestedKey
 * @returns The value of the nested field.
 *
 * TODO: The value of this could be anything.
 */
export const getNestedKeyValue = <T extends DraftFieldType>(
    fieldValue: DraftFieldValue<T> | undefined,
    nestedKey: string
) => {
    const isObject = typeof fieldValue === 'object' && fieldValue !== null && !Array.isArray(fieldValue)

    if (!isObject || !Object.prototype.hasOwnProperty.call(fieldValue, nestedKey)) return null

    // @ts-ignore
    return fieldValue[nestedKey]
}
