import { ConfigLogicFields, Matcher } from '@common/types/form/formConfig'
import {
    DraftStep,
    CreateMakeSetDraftFieldError,
    StepDraft,
    DraftField,
    DraftFieldType,
    DraftFieldValue,
    SetDraftFieldError,
    DraftBaseValidation,
    DraftTextInputField,
} from '@common/types/form/formDraft'
import { validateFieldRender } from '@common/helpers/form/logicHelpers'
import { objectKeys } from 'lib/utils/helpers'
import { AnyObject } from 'lib/types/advanced'
import { Validation } from 'lib/components/forms/textInput'
import { produce } from 'immer'
import { FieldType } from '@common/types/form/types'
/**
 *
 * @param step
 * @param stepDraft
 * @param fieldsLogic
 * @param createMakeSetFieldError
 * @returns True if the step is valid, false otherwise.
 */
export const validateStep = (
    step: DraftStep | undefined,
    stepDraft: StepDraft | undefined,
    fieldsLogic: ConfigLogicFields | undefined,
    createMakeSetFieldError?: CreateMakeSetDraftFieldError
): boolean => {
    if (!step || !stepDraft) return false
    const makeSetFieldError = createMakeSetFieldError?.(step.uuid)

    const validFields = step.fields.map((field) => {
        const fieldLogic = fieldsLogic?.[field.uuid]
        const draftFieldValue = stepDraft?.[field.uuid]

        if (fieldLogic) {
            const isFieldVisible = validateFieldRender(field, stepDraft, fieldsLogic)

            // If field is not visible, the required validation can be ignored.
            if (!isFieldVisible) return true

            return validateField(field, draftFieldValue, makeSetFieldError?.(field.uuid))
        }

        return validateField(field, draftFieldValue, makeSetFieldError?.(field.uuid))
    })

    return !validFields.includes(false)
}

/**
 *
 * @param field
 * @param draftFieldValue
 * @param setFieldError
 * @description Validates a field based on the field type and the field value. Some field types need special validation, as the fields contain multiple input elements.
 * @returns A boolean indicating if the field is valid or not.
 */
export const validateField = <T extends DraftFieldType = DraftFieldType>(
    field: DraftField,
    draftFieldValue: DraftFieldValue<T> | undefined,
    setFieldError?: SetDraftFieldError,
    signUpFieldIds?: Set<string>
): boolean => {
    switch (field.type) {
        case FieldType.SIGNUP: {
            const typecastedDraftFieldValue = draftFieldValue as DraftFieldValue<FieldType.SIGNUP> | undefined
            const formattedError = getNestedFieldErrors(field.props, typecastedDraftFieldValue, signUpFieldIds)

            if (formattedError) setFieldError?.(formattedError)

            return Boolean(!formattedError)
        }

        case FieldType.TEXT_INPUT: {
            const typecastedDraftFieldValue = draftFieldValue as DraftFieldValue<FieldType.TEXT_INPUT> | undefined

            const error = getTextInputFieldError(field, typecastedDraftFieldValue)

            if (error) setFieldError?.(error)

            return Boolean(draftFieldValue)
        }

        default: {
            const { required } = field.validation

            if (!draftFieldValue && required) setFieldError?.(field.validation.errorMessage)

            return Boolean(draftFieldValue)
        }
    }
}

type MinimalNestedDraftField<T> = {
    [key in keyof T]?: unknown
}

type MinimalNestedFieldProps<T> = {
    [key in keyof T]: {
        uuid: string
        validation: DraftBaseValidation
    }
}

/**
 *
 * @param fields {ConfigSignupFieldProps} - field with nested fields (props) in Field. For example {ConfigSignupFieldProps} in {ConfigSignupField}
 * @param draftFieldValue {DraftSignupFieldValue} - nested field with value
 * @returns Formatted errors to be set in state.
 */
const getNestedFieldErrors = <T extends AnyObject, U extends AnyObject | null>(
    fields: MinimalNestedFieldProps<T>,
    draftFieldValue: MinimalNestedDraftField<T> | undefined,
    signUpFieldIds?: Set<string>
): Partial<Record<keyof U, string>> | null => {
    return objectKeys(fields).reduce(
        (acc, nestedKey) => {
            const nestedField = fields[nestedKey]
            const { matcher, required } = nestedField.validation

            if (signUpFieldIds && !signUpFieldIds.has(nestedField.uuid)) return acc

            const fieldValue = draftFieldValue?.[nestedKey]
            let errorMessage

            if (matcher === Matcher.Email && !validateEmail(fieldValue as string)) {
                errorMessage = 'Die E-Mail-Adresse ist nicht gültig.'
            }

            if (!fieldValue && required) {
                errorMessage = nestedField.validation.errorMessage
            }

            if (errorMessage) {
                return {
                    ...(acc || {}),
                    [nestedKey]: errorMessage,
                }
            }

            return acc
        },
        null as Partial<Record<keyof U, string>> | null
    )
}

const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email)

const getTextInputFieldError = (
    field: DraftTextInputField,
    draftFieldValue: DraftFieldValue<FieldType.TEXT_INPUT> | undefined
) => {
    const { min, max, required } = field.validation

    if (field.props.type === 'number') {
        if (min && draftFieldValue && Number(draftFieldValue) < min)
            return `Bitte geben Sie eine Zahl ein, die größer oder gleich ${min} ist.`
        if (max && draftFieldValue && Number(draftFieldValue) > max)
            return `Bitte geben Sie eine Zahl ein, die kleiner oder gleich ${max} ist`
    }

    if (field.props.type === 'text') {
        if (min && draftFieldValue && draftFieldValue.length < min) return `Bitte geben Sie mindestens ${min} Zeichen ein`
        if (max && draftFieldValue && draftFieldValue.length > max) return `Bitte geben Sie maximal ${max} Zeichen ein`
    }

    if (required && !draftFieldValue) return field.validation.errorMessage

    return null
}

export const makeValidateExtremum =
    (type: 'text' | 'number' | 'password', min?: number, max?: number) =>
    (value: string): Validation[] => {
        if (type === 'number') {
            const number = Number(value)
            const characterMax = String(max).length

            return produce<Validation[]>([], (draft) => {
                if (!min && !max) draft.push({ isValid: true })

                if (min) {
                    const errorMessage = `Bitte geben Sie eine Zahl ein, die größer oder gleich ${min} ist`
                    draft.push({
                        isValid: number >= min,
                        onInvalid: (setError, currentValidation, prevValidation) => {
                            const currentValue = currentValidation.value

                            if (currentValue === '') return setError(null)

                            const prevValueLength = prevValidation?.value?.length || 0

                            if (prevValueLength >= currentValue.length) {
                                setError(errorMessage)
                            }

                            if (currentValue.length === characterMax && Number(currentValue) < min) {
                                setError(errorMessage)
                            }
                        },
                    })
                }

                if (max) {
                    draft.push(
                        {
                            isValid: value.length <= characterMax,
                            onInvalid: () => true,
                        },
                        {
                            isValid: number <= max,
                            onInvalid: (setError) => {
                                setError(`Bitte geben Sie eine Zahl ein, die kleiner oder gleich ${max} ist`)
                            },
                        }
                    )
                }
            })
        }

        return produce<Validation[]>([], (draft) => {
            if (!min && !max) draft.push({ isValid: true })

            if (min) {
                draft.push({
                    isValid: value.length >= min,
                    onInvalid: (setError, currentValidation, prevValidation) => {
                        const currentValue = currentValidation.value

                        if (currentValue === '') return setError(null)

                        const prevValueLength = prevValidation?.value?.length || 0

                        if (prevValueLength >= currentValue.length) {
                            setError(`Bitte geben Sie mindestens ${min} Zeichen ein.`)
                        }
                    },
                })
            }

            if (max) {
                draft.push({
                    isValid: value.length <= max,
                    onInvalid: () => true,
                })
            }
        })
    }
