import { AuthResponseMeta, CheckPasswordPayload, CompletePasswordResetPayload, EmailTotpLoginPayload, LoginPayload, Me, RequestEmailTotpLoginLinkPayload, RequestEmailToTypeFormPayload, RequestLoginMethodPayload, RequestLoginMethodResponse, RequestPasswordResetPayload, SetPasswordPayload, SignupRequestPayload, SignupRequestPayloadData, SignupResponeMeta, TypeFormVerifyRetry, VerifyEmailPayload } from '@api/auth/authenticationTypes'
import { getTrackingParamsFromCookie } from '@common/helpers/form/formHelpers'
import { TrackingQuery, TrackingTags } from '@utils/urls'
import { AxiosRequestConfig } from 'axios'
import { createApiUri, EnterApi } from 'lib/api/apiFactory'
import { validateAndSignInWithCustomToken } from 'lib/utils/firebaseAuthentication'

type BaseUserInclude = 'user' | 'building'

export const AUTH_CALLS = {
    queries: {
        me: {
            getKey: () => ['me'],
            call: (enterApi: EnterApi, config: AxiosRequestConfig = {}) => {
                return enterApi
                    .get<Me>(createApiUri<BaseUserInclude>('/main/auth/me', { user: true }), undefined, config)
                    .then(({ data }) => data)
            },
        },
        adminMe: {
            getKey: () => ['adminMe'],
            call: (enterApi: EnterApi, config: AxiosRequestConfig = {}) => {
                return enterApi
                    .get<Me>(createApiUri<BaseUserInclude>('/main/admin/auth/me', { user: true }), undefined, config)
                    .then(({ data }) => data)
            },
        },
    },

    mutations: {
        requestLoginMethod: {
            getKey: () => ['requestLoginMethod'],
            call: (enterApi: EnterApi, payload: RequestLoginMethodPayload) =>
                enterApi
                    .post<RequestLoginMethodResponse, RequestLoginMethodPayload>(
                        '/main/auth/email-password/check',
                        payload
                    )
                    .then((response) => response.data),
        },

        resendEmailVerificationLink: {
            getKey: () => ['account-email-resend-verification'],
            call: (enterApi: EnterApi) =>
                enterApi
                    .post<undefined, void>('/main/auth/account-email/resend-verification', undefined)
                    .then(({ data }) => data),
        },

        requestEmailTotpLoginLink: {
            getKey: () => ['email-totp-request-link'],
            call: (enterApi: EnterApi, payload: RequestEmailTotpLoginLinkPayload) =>
                enterApi
                    .post<undefined, RequestEmailTotpLoginLinkPayload>(
                        '/main/auth/email-totp/request-verification',
                        payload
                    )
                    .then(({ data }) => data),
        },

        loginWithTotp: {
            getKey: () => ['email-totp-verify'],
            call: (enterApi: EnterApi, payload: EmailTotpLoginPayload) => {
                return enterApi.post<Me, EmailTotpLoginPayload, AuthResponseMeta>(
                    createApiUri('/main/auth/email-totp/verify', { user: true }),
                    payload
                )
            },
        },

        loginWithTypeformVerify: {
            getKey: () => ['email-typeform-verify'],
            call: (enterApi: EnterApi, payload: RequestEmailToTypeFormPayload) => {
                return enterApi.post<TypeFormVerifyRetry, RequestEmailToTypeFormPayload, AuthResponseMeta>(
                    createApiUri('/main/typeform/auth', { user: true }),
                    payload
                )
            },
        },

        verifyEmail: {
            getKey: () => ['account-email-verify'],
            call: (enterApi: EnterApi, payload: VerifyEmailPayload) =>
                enterApi
                    .post<undefined, VerifyEmailPayload>('/main/auth/account-email/verify', payload)
                    .then(({ data }) => data),
        },

        setPassword: {
            getKey: () => ['setPassword'],
            call: async (
                enterApi: EnterApi,
                payload: SetPasswordPayload,
                encryptPassword: (rawPassword: string) => Promise<string>
            ) => {
                const encryptedNewPassword = await encryptPassword(payload.newPassword)

                if (!encryptedNewPassword) return Promise.reject('Failed to encrypt password')

                if (payload.previousPassword) {
                    const encryptedPreviousPassword = await encryptPassword(payload.previousPassword)

                    if (!encryptedPreviousPassword) return Promise.reject('Failed to encrypt password')

                    return enterApi
                        .post<undefined, SetPasswordPayload>('/main/auth/email-password/set-password', {
                            newPassword: encryptedNewPassword,
                            previousPassword: encryptedPreviousPassword,
                        })
                        .then((response) => response.data)
                }

                return enterApi
                    .post<undefined, SetPasswordPayload>('/main/auth/email-password/set-password', {
                        newPassword: encryptedNewPassword,
                    })
                    .then((response) => response.data)
            },
        },

        checkPassword: {
            getKey: () => ['checkPassword'],
            call: async (
                enterApi: EnterApi,
                payload: CheckPasswordPayload,
                encryptPassword: (rawPassword: string) => Promise<string>
            ) => {
                const encryptedNewPassword = await encryptPassword(payload.password)

                if (!encryptedNewPassword) return Promise.reject('Failed to encrypt password')

                return enterApi
                    .post<undefined, CheckPasswordPayload>('/main/auth/email-password/check-password', {
                        password: encryptedNewPassword,
                        email: payload.email,
                    })
                    .then((response) => response.data)
            },
        },

        login: {
            getKey: () => ['login'],
            call: async (
                enterApi: EnterApi,
                { email, password }: LoginPayload,
                encryptPassword: (rawPassword: string) => Promise<string>
            ) => {
                const encryptedPassword = await encryptPassword(password)

                if (!encryptedPassword) {
                    return Promise.reject('Failed to encrypt password')
                }

                return enterApi
                    .post<Me, LoginPayload, AuthResponseMeta>(
                        createApiUri<BaseUserInclude>('/main/auth/email-password/login', {
                            user: true,
                            building: true,
                        }),
                        { email, password: encryptedPassword }
                    )
                    .then((response) => response)
            },
        },

        logout: {
            getKey: () => ['logout'],
            call: (enterApi: EnterApi) =>
                enterApi.post<undefined, void>('/main/auth/logout', undefined).then(({ data }) => data).catch(() => {}),
        },

        signup: {
            getKey: (formUuid?: string, gclid?: string) => ['signup-form', formUuid, gclid],
            call: (
                enterApi: EnterApi,
                payload: SignupRequestPayloadData,
                formUuid?: string,
                trackingQueryParams?: TrackingQuery
            ) => {
                const trackingParamsFromCookie = getTrackingParamsFromCookie(Object.values(TrackingTags))
                const analytics = { ...trackingQueryParams, ...trackingParamsFromCookie }

                return enterApi
                    .post<Me, SignupRequestPayload, SignupResponeMeta>(
                        createApiUri<BaseUserInclude>(`/main/auth/signup/email/${formUuid}`, {
                            user: true,
                            building: true,
                        }),
                        { data: payload, analytics }
                    )
                    .then((response) => validateAndSignInWithCustomToken(response.meta.idToken, response))
            },
        },

        requestPasswordReset: {
            getKey: () => ['request-password-reset'],
            call: (enterApi: EnterApi, payload: RequestPasswordResetPayload) =>
                enterApi
                    .post<undefined, RequestPasswordResetPayload>('/main/auth/account/request-password-reset', payload)
                    .then(({ data }) => data),
        },

        completePasswordReset: {
            getKey: () => ['complete-password-reset'],
            call: async (
                enterApi: EnterApi,
                { password, ...restPayload }: CompletePasswordResetPayload,
                encryptPassword: (rawPassword: string) => Promise<string>
            ) => {
                const encryptedPassword = await encryptPassword(password)

                if (!encryptedPassword) {
                    return Promise.reject('Failed to encrypt password')
                }

                return enterApi
                    .post<undefined, CompletePasswordResetPayload>('/main/auth/account/complete-password-reset', {
                        password: encryptedPassword,
                        ...restPayload,
                    })
                    .then(({ data }) => data)
            },
        },
    },
}
