import { CookieValueTypes, getCookie } from 'cookies-next'

import {
    DraftField,
    DraftFieldValue,
    DraftForm,
    DraftMultiFieldValue,
    DraftStep,
    FormDraft,
    StepDraft,
} from '@common/types/form/formDraft'
import { validateStep } from '@common/helpers/form/validationHelpers'
import { computeNextStepUuid } from '@common/helpers/form/logicHelpers'
import { ParsedUrlQuery } from 'querystring'
import { FieldType } from '@common/types/form/types'
import { FormQuery, TrackingQuery, TrackingTags } from '@utils/urls'

/**
 *
 * @param activeStepUuid
 * @param nextStepUuid
 * @param setActiveStepUuid
 * @param stepHistory
 * @param setStepHistory
 * @description This function is used to navigate to the next step in the form.
 * @returns Void
 */
export const incrementStep = (
    activeStepUuid: string,
    nextStepUuid: string | undefined,
    setActiveStepUuid: (activeStep: string) => void,
    stepHistory: string[],
    setStepHistory: (history: string[]) => void
) => {
    if (nextStepUuid) setActiveStepUuid(nextStepUuid)
    setStepHistory([...stepHistory, ...(activeStepUuid ? [activeStepUuid] : [])])
}

type ComputedHistoryReturnType = {
    computedHistory: string[]
    redirectStepUuid: string
}

/**
 *
 * @param rawStepUuid
 * @param form
 * @param initialSortedStepUuids
 * @param draft
 * @description This function is used to compute the valid path of steps that the user has taken.
 * @returns An object containing the computed history and the lastCheckedStepUuid.
 */
export const getComputedHistory = (
    rawStepUuid: string | undefined,
    form: DraftForm | undefined,
    initialSortedStepUuids: string[],
    draft: FormDraft | null
): ComputedHistoryReturnType => {
    const initialStepUuid = initialSortedStepUuids[0] as string // TODO

    const computeHistory = (
        stepUuid: string,
        partialHistory: string[]
    ): { computedHistory: string[]; redirectStepUuid: string } => {
        if (rawStepUuid === stepUuid) {
            return { computedHistory: partialHistory, redirectStepUuid: rawStepUuid }
        }

        const currentStep = form?.steps.find((step) => step.uuid === stepUuid)

        const isCurrentStepValid = validateStep(currentStep, draft?.[stepUuid], form?.logic.fields)
        const computedNextStepUuid = computeNextStepUuid(draft, stepUuid, form?.logic.steps, initialSortedStepUuids)
        const isAnsweredStep = form?.steps.find((step) => step.uuid === stepUuid)?.answered

        if (!computedNextStepUuid || !isAnsweredStep || !isCurrentStepValid)
            return { computedHistory: partialHistory, redirectStepUuid: stepUuid }

        return computeHistory(computedNextStepUuid, [...partialHistory, stepUuid])
    }

    return computeHistory(initialStepUuid, [])
}

/**
 *
 * @param query
 * @param keys
 * @description Check valid form query params from an URL
 * @returns valid form query params
 */

export const getValidFormQueryParams = (query: ParsedUrlQuery, keys: (keyof FormQuery)[]): FormQuery | undefined => {
    return keys.every((key) => typeof query[key] === 'string')
        ? keys.reduce((acc, key) => {
              return {
                  ...acc,
                  [key]: query[key],
              }
          }, {} as FormQuery)
        : undefined
}

/**
 *
 * @param query
 * @param keys
 * @description Check valid query params from an URL
 * @returns valid query params
 */
export const getValidTrackingParams = (
    query: ParsedUrlQuery,
    keys: (keyof TrackingQuery)[]
): TrackingQuery | undefined => {
    return keys.reduce((acc, key) => {
        if (!query[key] || typeof query[key] !== 'string') return acc
        return {
            ...acc,
            [key]: query[key],
        }
    }, {} as TrackingQuery)
}

/**
 *
 * @param tags
 * @description Get tracking tags with values from cookie
 * @returns Array of tags with values from cookie
 */
export const getTrackingParamsFromCookie = (tags: `${TrackingTags}`[]): { [tag: string]: CookieValueTypes } => {
    return tags.reduce((acc, tag) => {
        const cookieValue = getCookie(tag)
        if (cookieValue === undefined || !cookieValue || !isCookieValueType(cookieValue)) return acc
        return {
            ...acc,
            [tag]: cookieValue,
        }
    }, {})
}

/**
 * guard function isCookieValueType that checks if the value matches the CookieValueTypes type. The function returns
 * true if the value is of type string, boolean, undefined, or null, and false otherwise.
 * @param value
 * @returns falsy value
 */
export const isCookieValueType = (value: unknown): value is CookieValueTypes => {
    return typeof value === 'string' || typeof value === 'boolean' || value === undefined || value === null
}

/**
 *
 * @param currentStep
 * @param previousStep
 * @return boolean
 * */
export const checkIfValueChanged = (currentStep: DraftStep, previousStep: StepDraft): boolean => {
    return (
        Boolean(currentStep.answered) &&
        currentStep.fields.some(
            (field) =>
                field.type !== FieldType.SIGNUP &&
                field.type !== FieldType.MULTI_FIELDS &&
                field.props.value !== previousStep[field.uuid]
        )
    )
}

export const mapFormToDraft = (form: DraftForm | undefined): FormDraft | null => {
    if (!form) return null

    return form.steps.reduce<FormDraft>((stepsAcc, step) => {
        const draftFields = step.fields.reduce((fieldsAcc, field) => {
            const initialFieldValue = computeInitialValue(field)

            if (initialFieldValue) {
                return {
                    ...fieldsAcc,
                    ...initialFieldValue,
                }
            }

            return fieldsAcc
        }, {})

        return {
            ...stepsAcc,
            [step.uuid]: { ...draftFields },
        }
    }, {})
}

export const computeInitialValue = <T extends DraftField>(
    field: T
): { [uuid: string]: DraftFieldValue<DraftField['type']> | undefined } | null => {
    switch (field.type) {
        case FieldType.TEXT_INPUT: {
            return {
                [field.uuid]: field.props.value,
            }
        }
        case FieldType.CHECKBOX_SELECT: {
            return {
                [field.uuid]: field.props.value,
            }
        }

        case FieldType.SIGNUP: {
            const propsFirstName = field.props.firstName
            const propsLastName = field.props.lastName
            const propsCountryCode = field.props.countryCode
            const propsPhoneNumber = field.props.phoneNumber
            const propsEmail = field.props.email
            const propsNote = field.props.note

            return {
                [field.uuid]: {
                    firstName: propsFirstName.value,
                    lastName: propsLastName.value,
                    countryCode: propsCountryCode.value,
                    phoneNumber: propsPhoneNumber.value,
                    email: propsEmail.value,
                    note: propsNote.value,
                },
            }
        }

        case FieldType.MULTI_FIELDS: {
            const result: DraftMultiFieldValue[] = field.props.fields.map((f) =>
                f.reduce((acc, fld) => ({ ...acc, [fld.cKey]: fld.value }), {})
            )
            return {
                [field.uuid]: result,
            }
        }

        case FieldType.CALENDLY: {
            return {
                [field.uuid]: field.props.value,
            }
        }

        default: {
            return null
        }
    }
}

/**
 * Check values in FormDraft
 * @param obj FormDraft or Array of objects in case multi-fields
 * @return boolean
 * */
export const checkRecursivelyValues = (obj: Record<string, unknown> = {}): boolean => {
    if (!obj) return false

    return Object.values(obj).some((value) => {
        // for multi-fields
        if (Array.isArray(value)) {
            //At the moment allowed one filled input. So if at least one question was filled, accept the step.
            return value.some((values) => checkRecursivelyValues(values))
        }
        return !!value
    })
}
