import { Values } from 'lib/utils/helpers'
import { FieldType } from '@common/types/form/types'

/**
 * Different types of fields.
 */
export type ConfigField =
    | ConfigMultiField
    | ConfigTextInputField
    | ConfigCheckboxSelectField
    | ConfigCheckboxField
    | ConfigSignupField
    | ConfigCalendlyField

/**
 * A form to be sent to the frontend.
 */
export interface ConfigForm {
    uuid: string
    title: string
    heroImage?: string
    isSignUpForm: boolean
    partnerLogo?: string
    steps: [ConfigStep, ...ConfigStep[]]
    logic: ConfigLogic
}

export interface ConfigStep {
    uuid: string
    title: string
    fields: [ConfigField, ...ConfigField[]]
    nextButtonLabel: string
    primaryButtonLabel?: string
    secondaryButtonLabel?: string
    answered?: boolean
}

/**
 * The shared properties of all fields.
 */
export interface ConfigBaseField {
    uuid: string
    key: string
    title: string
    subtitle?: string
    description?: string
    validation: ConfigBaseValidation
}

/**
 * A multi field.
 */
export type ConfigMultiField = ConfigBaseField & {
    type: FieldType.MULTI_FIELDS
    properties: {
        title: string
        fields: ConfigMultiFieldProps[]
    }
}

export type ConfigMultiFieldProps = {
    key: string
    title: string
    type: FieldType.TEXT_INPUT | FieldType.CHECKBOX_SELECT
    values?: string[]
    answered?: boolean[]
    validation: ConfigBaseValidation & Extremum
    properties: ConfigCheckboxSelectFieldProps & ConfigTextInputFieldProps
    description: string
}

/**
 * A text input field.
 */
export type ConfigTextInputField = ConfigBaseField & {
    type: FieldType.TEXT_INPUT
    properties: ConfigTextInputFieldProps
    validation: Extremum
}

export type ConfigTextInputFieldProps = {
    type: 'text' | 'email' | 'password' | 'website' | 'number'
    placeholder: string
    multiline?: boolean
    suffix?: string
    value?: string
    animatedLabel?: string
    keyboardType?: 'text' | 'number'
    label?: string
}

/**
 * A checkbox select field.
 */
export type ConfigCheckboxSelectField = ConfigBaseField & {
    type: FieldType.CHECKBOX_SELECT
    properties: ConfigCheckboxSelectFieldProps
}

export type ConfigCheckboxSelectFieldProps = {
    multiSelect: boolean
    options: ConfigCheckboxSelectFieldOption[]
    value?: string
}

interface ConfigCheckboxSelectFieldOption {
    label: string
    value: string
    iconUrl?: string
    selectedIconUrl?: string
}

/**
 * A calendly select field.
 */
export type ConfigCalendlyField = ConfigBaseField & {
    type: FieldType.CALENDLY
    properties: ConfigCalendlyFieldProps
}

export type ConfigCalendlyFieldProps = {
    link: string
    value?: string
    answered?: boolean
}

/**
 * A signup field.
 */
export type ConfigSignupField = ConfigBaseField & {
    type: FieldType.SIGNUP
    properties: ConfigSignupFieldProps
}

interface ConfigSignupFieldProps {
    firstName: {
        uuid: string
        key: string
        label: string
        value?: string
        validation: ConfigBaseValidation
    }
    lastName: {
        uuid: string
        key: string
        label: string
        value?: string
        validation: ConfigBaseValidation
    }
    countryCode: {
        uuid: string
        key: string
        value?: string
        validation: ConfigBaseValidation
    }
    phoneNumber: {
        uuid: string
        key: string
        placeholder: string
        value?: string
        validation: ConfigBaseValidation
    }
    email: {
        uuid: string
        key: string
        placeholder: string
        value?: string
        validation: ConfigBaseValidation
    }
    note: {
        uuid: string
        key: string
        placeholder: string
        value?: string
        validation: ConfigBaseValidation
    }
    additionalTermsAndConditions?: {
        uuid: string
        key: string
        label: string
        validation: ConfigBaseValidation
        termsAgreementText?: string
    }
}

/**
 * A checkbox field.
 */
export type ConfigCheckboxField = ConfigBaseField & {
    type: FieldType.CHECKBOX
    validation: ConfigCheckboxValidations
    properties: ConfigCheckboxFieldProps
}

interface ConfigCheckboxFieldProps {
    label: string
    value?: boolean
}

interface ConfigCheckboxValidations extends ConfigBaseValidation {
    allowedValue?: boolean
}

/**
 * The shared validations of all fields.
 */
export interface ConfigBaseValidation {
    required: boolean
    errorMessage?: string
    matcher?: Values<Matcher>
}

export interface Extremum {
    min?: number
    max?: number
}

export enum Matcher {
    Email = 'email',
    Phone = 'phone',
}

// ------------- //
// --- Logic --- //
// ------------- //

export interface ConfigLogic {
    steps?: ConfigLogicSteps
    fields?: ConfigLogicFields
}

export interface ConfigLogicSteps {
    [key: string]: ConfigLogicStep
}

export interface ConfigLogicStep {
    type: 'jump'
    actions: ConfigLogicAction[]
}

export interface ConfigLogicFields {
    [key: string]: ConfigLogicField
}

export type ConfigLogicField = {
    type: 'visibility'
    condition: ConfigLogicActionCondition
}

export interface ConfigLogicAction {
    condition: ConfigLogicActionCondition
    destination: ConfigLogicActionDestination
}

type ConfigLogicActionDestination =
    | {
          value: string
          type: 'step'
      }
    | {
          type: 'end'
      }

export type ConfigLogicActionCondition =
    | {
          operator: ConfigLogicActionOperators
          variables: [ConfigLogicActionVariable, ConfigLogicActionVariable]
      }
    | {
          operator: ConfigLogicActionNestableOperators
          variables: ConfigLogicActionCondition[]
      }
    | {
          operator: ConfigLogicActionElseOperator
          variables: []
      }

type ConfigLogicActionOperators =
    | 'greaterThan'
    | 'lessThan'
    | 'equals'
    | 'notEquals'
    | 'greaterThanOrEquals'
    | 'lessThanOrEquals'

type ConfigLogicActionNestableOperators = 'and' | 'or'
type ConfigLogicActionElseOperator = 'else'

export type ConfigLogicActionVariable =
    | {
          type: 'constant'
          value: string
      }
    | {
          type: 'field'
          value: string
          nestedKey?: string
      }
