/**
 * A function that checks if a value is numeric.
 * @param value A string a number or a possibly undefined value.
 * @returns A boolean indicating whether the value is numeric.
 *
 * TODO:
 * @note Be careful when using or expanding, as there are mutliple exceptions.
 * @see https://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
 */
export const isNumeric = (value: string | number | undefined): value is number => !isNaN(Number(value))

/**
 * A function that shallowely compares two arrays with primitive values for equality.
 * @param a An array.
 * @param b An array.
 * @returns A boolean indicating whether the arrays are equal.
 */
export const shallowEqual = <T extends Array<unknown>, K extends Array<unknown>>(a: T, b: K): boolean =>
    Boolean(Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]))
